import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  providers,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import useStepper from "../../../../../hooks/useStepper";
import { downloadApplicationForm } from "../../../../../graphql/queries/manageMySharedCost";
import { formatCurrency } from "../../../../../helpers";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const PlanStatus = ({ planDetails }) => (
  <Stack
    direction={{ lg: "row", md: "row", sm: "column" }}
    alignItems="center"
    gap={2}
  >
    <Box className="plan-with-provider">
      <Typography className="plan-with-provider-text">
        Plan with {planDetails?.provider?.name}
      </Typography>
    </Box>
    <Stack gap={2} direction="row">
      <Typography className="status-heading">Plan Status</Typography>
      <Typography className="status-name">
        {planDetails?.plan_status?.name}
      </Typography>
    </Stack>
  </Stack>
);

PlanStatus.propTypes = {
  planDetails: PropTypes.object,
};

const PlanInfo = ({ planDetails, amendedPlan, planLength }) => {
  const { setSaveError, setErrorToast } = useStepper();
  const navigate = useNavigate();

  const [downloadForm, { loading: downloadLoading }] = useLazyQuery(
    downloadApplicationForm
  );

  const handleDownloadForm = async (planId) => {
    downloadForm({
      variables: {
        plan_id: planId,
      },
      onCompleted: (data) => {
        const applicationFormLink = data?.plan_details?.plans?.application_form;
        window.open(applicationFormLink, "_blank");
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const isPlanAcceptedOrSingle =
    !["Cancelled", "Opted-out", "Opt Out Pending"].includes(
      planDetails?.plan_status?.name
    ) && planLength < 2;

  return (
    <Stack
      className="info-container"
      direction={{ lg: "row", md: "row", sm: "column" }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      gap={3}
    >
      <Stack
        gap={1}
        direction="column"
        className="align-left"
        sx={{
          display: "flex",
          padding: "1.5rem",
        }}
      >
        <Stack direction="column" className="payroll-table" gap={1}>
          <Stack direction={"row"} alignItems={"end"}>
            <Typography
              className="plan"
              minWidth="150px"
              marginRight={"11px"}
              sx={{ textAlign: "end" }}
            >
              Start Date&nbsp;
            </Typography>
            <Typography className="plan">
              <span className="value">
                {moment(planDetails?.created_at).format("D MMMM YYYY")}
              </span>
            </Typography>
          </Stack>
          <Stack direction="row" alignItems={"end"}>
            <Typography
              className="plan"
              marginRight={"11px"}
              minWidth="150px"
              sx={{ textAlign: "end" }}
            >
              Payroll frequency&nbsp;
            </Typography>
            <Typography className="plan">
              <span className="value">{planDetails?.avc_interval || ""}</span>
            </Typography>
          </Stack>
        </Stack>
        {!amendedPlan ? (
          <Box className="mt-30">
            {isPlanAcceptedOrSingle ? (
              <Stack
                direction={{
                  xs: "column",
                  xsm: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                }}
                gap={1}
              >
                <Stack direction="row" alignItems="center">
                  <Typography className="currency-contribution text-bold">
                    Contribution per payroll&nbsp;{"  "}
                  </Typography>
                  <Typography className="currency underline-text">
                    {formatCurrency(planDetails?.total_avc_amount_requested)}
                  </Typography>
                </Stack>
                {planDetails?.plan_status?.name === "Accepted" ? (
                  <Box
                    display={"flex"}
                    marginLeft={"20px"}
                    alignItems={"center"}
                    className="amend-container"
                  >
                    <Typography className="underline-text med-purple-text lowercase-text">
                      amend
                    </Typography>
                    <LoadingButton
                      trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                      icon={<CreateOutlinedIcon className="med-purple-text" />}
                      disabled={downloadLoading}
                      handleClick={() =>
                        navigate("/amend_my_shared_cost_avc", {
                          state: { backUrl: "/manage_my_shared_cost_avc" },
                        })
                      }
                      styleClass="med-purple-text"
                    />
                  </Box>
                ) : null}
              </Stack>
            ) : null}
            <Stack
              className="mt-30 downlaod-application"
              gap={1}
              onClick={() => handleDownloadForm(planDetails?.id)}
              sx={{ cursor: "pointer" }}
            >
              <Typography className="home-typography align-self-center fs-20">
                Download my completed application form
              </Typography>
              <LoadingButton
                trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                disabled={downloadLoading}
                loading={downloadLoading}
                onClick={() => handleDownloadForm(planDetails?.id)}
                styleClass="med-purple-text hover-effect-white"
                icon={<FileDownloadOutlinedIcon />}
              />
            </Stack>
            {isPlanAcceptedOrSingle ? (
              <Stack justifyContent="flex-start" alignItems="flex-start">
                <LoadingButton
                  buttonTitle="Cancel"
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  handleClick={() => navigate("/cancel_my_plan")}
                  styleClass="warn-red-text fw-400 px-0 text-start pi-0 d-block underline-text mt-10 capitalize-text hover-effect-white"
                  disabled={downloadLoading}
                />
              </Stack>
            ) : null}
          </Box>
        ) : null}
      </Stack>

      {planDetails?.plan_status?.name === "Accepted" &&
      planDetails?.provider?.name?.toLowerCase() ===
        providers.LEGAL_AND_GENERAL ? (
        <Stack
          gap={1}
          direction="column"
          sx={{
            padding: "1.5rem",
          }}
        >
          <Typography className="home-typography">
            See the total value in your pension pot <br />
            and change where your money is invested.{" "}
          </Typography>
          <Typography className="home-typography italic-font mt-10">
            Leaves My Money Matters
          </Typography>
          <LoadingButton
            buttonTitle={`Manage on Legal & General`}
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
            handleClick={() => {
              window.open(
                `https://${process.env.REACT_APP_AUTH0_DOMAIN}/samlp/${process.env.REACT_APP_LNG_CLIENT_ID}`,
                "_blank"
              );
            }}
            styleClass={"book-place-btn font-bold mt-10"}
            icon={<OpenInNewIcon className="mr-5" />}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

PlanInfo.propTypes = {
  planDetails: PropTypes.object,
  amendedPlan: PropTypes.bool,
  planLength: PropTypes.number,
};

const PlanDetails = ({
  planDetails,
  amendedPlan,
  planStatusBg,
  planLength,
}) => {
  return (
    <>
      <Box
        className="banner-container"
        sx={{ backgroundImage: `url(${planStatusBg})` }}
      >
        <Stack direction="column">
          <PlanStatus planDetails={planDetails} />
        </Stack>
      </Box>
      <PlanInfo
        planDetails={planDetails}
        amendedPlan={amendedPlan}
        planLength={planLength}
      />
    </>
  );
};

PlanDetails.propTypes = {
  organisation: PropTypes.object,
  planDetails: PropTypes.object,
  planStatusBg: PropTypes.string,
  amendedPlan: PropTypes.bool,
  planLength: PropTypes.number,
};

const mapStateToProps = (state) => ({
  organisation: state?.organisation?.organisation,
});

export default connect(mapStateToProps)(PlanDetails);
